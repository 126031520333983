export const debounce = (fn: (...args: any[]) => any, timeout = 300, leading = false) => {
  let timer: number | undefined;

  if (leading) {
    return (...args: unknown[]) => {
      if (!timer) {
        fn.apply(this, args);
      }
      clearTimeout(timer);
      timer = setTimeout(() => {
        timer = undefined;
      }, timeout);
    };
  }

  return (...args: unknown[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
    }, timeout);
  };
};
