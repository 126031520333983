import { BASE_URL, Locale } from "@/constants";
import { CollectiveLaborDisputesRegister } from "@/interfaces";

export default {
  async getCollectiveLaborDisputesRegister(
    locale: Locale,
    search: string,
  ): Promise<CollectiveLaborDisputesRegister[]> {
    const response = await fetch(
      `${BASE_URL}collective_labor_disputes_register?language=${locale}${
        search ? `&search=${search}` : ""
      }`,
      {
        method: "GET",
      },
    );
    if (response.status !== 200) {
      throw response.status;
    }
    return await response.json();
  },
};
