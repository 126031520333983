export const TabsConfig = {
  VACANCIES: [
    { label: "tabs.vacancies.actual", route: "/for-public/vacancies/actual" },
    { label: "tabs.vacancies.results", route: "/for-public/vacancies/results" },
    { label: "tabs.vacancies.info", route: "/for-public/vacancies/info" },
  ],
  REGULATORY: [
    { label: "tabs.regulatory.legislation", route: "/regulatory/legislation" },
    { label: "tabs.regulatory.activity", route: "/regulatory/activity" },
    { label: "tabs.regulatory.judicialPractice", route: "/regulatory/judicial-practice" },
  ],
};
