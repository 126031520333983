import { BASE_URL, Locale } from "@/constants";
import {
  JusticeActsResponse,
  LegislationListResponse,
  JudicialPracticeResponse,
  RegulatoryFilters,
  JudicialPracticeParams,
  LegislationListParams,
  JusticeActsParams,
} from "@/types";

export default {
  async getJudicialPractice(
    locale: Locale,
    queryParams?: JudicialPracticeParams,
  ): Promise<JudicialPracticeResponse[]> {
    const response = await fetch(
      `${BASE_URL}judicialpractice?language=${locale}&${new URLSearchParams(
        queryParams,
      ).toString()}`,
      {
        method: "GET",
      },
    );
    if (response.status !== 200) {
      throw response.status;
    }

    return await response.json();
  },

  async getLegislationList(
    locale: Locale,
    queryParams?: LegislationListParams,
  ): Promise<LegislationListResponse[]> {
    const response = await fetch(
      `${BASE_URL}legislation?language=${locale}&${new URLSearchParams(queryParams).toString()}`,
      {
        method: "GET",
      },
    );
    if (response.status !== 200) {
      throw response.status;
    }

    return await response.json();
  },

  async getJusticeActs(
    locale: Locale,
    queryParams: JusticeActsParams,
  ): Promise<JusticeActsResponse[]> {
    const response = await fetch(
      `${BASE_URL}justiceacts?language=${locale}&${new URLSearchParams(queryParams).toString()}`,
      {
        method: "GET",
      },
    );
    if (response.status !== 200) {
      throw response.status;
    }

    return await response.json();
  },

  //   filter-values
  async getJusticeActsFilters(locale: Locale): Promise<RegulatoryFilters> {
    const response = await fetch(`${BASE_URL}justiceacts/filters?language=${locale}`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }

    return await response.json();
  },

  async getLegislationFilters(locale: Locale): Promise<RegulatoryFilters> {
    const response = await fetch(`${BASE_URL}legislation/filters?language=${locale}`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }

    return await response.json();
  },

  async getJudicialPracticeFilters(locale: Locale): Promise<RegulatoryFilters> {
    const response = await fetch(`${BASE_URL}judicialpractice/filters?language=${locale}`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }

    return await response.json();
  },
};
