import { BASE_URL, Locale } from "@/constants";
import { UsefulLink } from "@/interfaces";

export default {
  async getUsefulLinks(locale: Locale): Promise<UsefulLink[]> {
    const response = await fetch(BASE_URL + "useful_links?language=" + locale, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }

    return response.json();
  },
};
