import { BASE_URL } from "@/constants";
import { ExternalDocumentResponse } from "@/interfaces";

export default {
  async getReports(): Promise<ExternalDocumentResponse[]> {
    const response = await fetch(BASE_URL + "reports", {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }

    return response.json();
  },
};
