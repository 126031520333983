<script setup lang="ts"></script>

<template>
  <table>
    <caption>
      {{
        $t("departments.table.caption")
      }}
    </caption>
    <tr>
      <th>{{ $t("departments.table.heading.position") }}</th>
      <th>{{ $t("departments.table.heading.receptionDays") }}</th>
      <th>{{ $t("departments.table.heading.receptionTime") }}</th>
    </tr>
    <tr>
      <td>
        {{ $t("departments.table.row1.position") }}
        <br />
        КУХНЮК Дмитро Володимирович
      </td>
      <td>{{ $t("departments.table.row1.receptionDays") }}</td>
      <td>15:00 – 17:00</td>
    </tr>
    <tr>
      <td>
        {{ $t("departments.table.row2.position") }}
        <br />
        ЗАПАРА Світлана Іванівна
      </td>
      <td>{{ $t("departments.table.row2.receptionDays") }}</td>
      <td>15:00 – 17:00</td>
    </tr>
    <tr>
      <td>
        {{ $t("departments.table.row3.position") }}
        <br />
        МАРЧУК Роман Валентинович
      </td>
      <td>{{ $t("departments.table.row3.receptionDays") }}</td>
      <td>15:00 – 17:00</td>
    </tr>
  </table>
</template>

<style scoped lang="scss">
table,
th,
td {
  border: 1px solid black;
  padding: 10px 5px 7px;
  font-size: 13px;
}

caption {
  caption-side: top;
  color: var(--black-1000);
  text-transform: uppercase;
  margin: 10px 0;
  display: table-caption;
  text-align: center;
}
</style>
