import dayjs from "dayjs";
import { DATE_FORMAT } from "@/constants/date";

const filters = {
  formatDate(timestamp: string, format = DATE_FORMAT) {
    if (!timestamp) return;

    return dayjs(new Date(timestamp)).format(format);
  },

  durationDays(startTimestamp?: string, endTimestamp?: string, format = "DD MMMM YYYY") {
    if (startTimestamp && endTimestamp) {
      const startDate = dayjs(new Date(startTimestamp));
      const startYear = startDate.year();
      const startMonth = startDate.month();
      const startDay = startDate.day();

      const endDate = dayjs(new Date(endTimestamp));
      const endYear = endDate.year();
      const endMonth = endDate.month();
      const endDay = endDate.day();

      const end = endDate.format(format);
      if (startYear === endYear && startMonth === endMonth && startDay === endDay) {
        return end;
      } else if (startYear === endYear && startMonth === endMonth && startDay !== endDay) {
        return `${startDate.format("DD")} - ${end}`;
      } else if (startYear === endYear && startMonth !== endMonth && startDay !== endDay) {
        return `${startDate.format("DD MMMM")} - ${end}`;
      } else {
        return `${startDate.format(format)} - ${end}`;
      }
    } else if (startTimestamp && !endTimestamp) {
      return dayjs(new Date(startTimestamp)).format(format);
    } else if (!startTimestamp && endTimestamp) {
      return dayjs(new Date(endTimestamp)).format(format);
    } else return "-";
  },

  durationTime(startTimestamp?: string, endTimestamp?: string) {
    if (startTimestamp && endTimestamp) {
      const startTime = dayjs(new Date(startTimestamp)).format("HH:mm");
      const endTime = dayjs(new Date(endTimestamp)).format("HH:mm");
      return startTime === endTime ? startTime : `${startTime} - ${endTime}`;
    } else if (startTimestamp && !endTimestamp) {
      return dayjs(new Date(startTimestamp)).format("HH:mm");
    } else if (!startTimestamp && endTimestamp) {
      return dayjs(new Date(endTimestamp)).format("HH:mm");
    } else return "-";
  },
};

export default filters;
