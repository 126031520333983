import { BASE_URL, Locale } from "@/constants";
import { Department } from "@/types";

export default {
  async getDepartments(locale: Locale): Promise<Department[]> {
    const response = await fetch(`${BASE_URL}departments?language=${locale}`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }

    return await response.json();
  },
};
