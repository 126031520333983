import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import dayjs from "dayjs";

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

const local = {
  en: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
  "uk-ua": {
    future: "через %s",
    past: "%s назад",
    s: "декілька секунд тому",
    m: "1 хвилина",
    mm: "%d хвилин",
    h: "година",
    hh: "%d годин(и)",
    d: "день",
    dd: "%d дні(в)",
    M: "місяць",
    MM: "%d місяці(в)",
    y: "рік",
    yy: "%d роки(ів)",
  },
};

dayjs.updateLocale("uk-ua", { relativeTime: local["uk-ua"] });

export function relativeDate(datetime: string): string {
  return dayjs().to(dayjs(new Date(datetime)));
}
