import { BASE_URL } from "@/constants";
import { ExternalDocumentResponse } from "@/interfaces";

export default {
  async getGovernmentProcurement(search?: string): Promise<ExternalDocumentResponse[]> {
    const query = search ? `name=${encodeURIComponent(search)}` : "";

    const response = await fetch(`${BASE_URL}government_procurement?${query}`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }

    return response.json();
  },
};
