import { PartiesEntitiesRegister } from "@/interfaces";
import { BASE_URL, Locale, LOCALE_KEY } from "@/constants";

interface QueryParams {
  locale?: Locale;
  limit?: number;
  offset?: number;
  search?: string;
  level?: string;
  region?: string;
  date?: string;
}
export default {
  async getPartiesEntitiesRegister(params: QueryParams): Promise<PartiesEntitiesRegister[]> {
    const queryParams = {
      language: params.locale || this.getLocale(),
      ...(params.limit && { limit: params.limit.toString() }),
      ...(params.offset && { offset: params.offset.toString() }),
      ...(params.level && { level: params.level }),
      ...(params.region && { region: params.region }),
      ...(params.search && { search: params.search }),
      ...(params.date && { date: params.date }),
    };
    const response = await fetch(
      `${BASE_URL}parties_entities_register?${new URLSearchParams(queryParams)}`,
      {
        method: "GET",
      },
    );
    if (response.status !== 200) {
      throw response.status;
    }
    return await response.json();
  },
  getLocale(): Locale {
    return (localStorage.getItem(LOCALE_KEY) as Locale) || Locale.Uk;
  },
};
