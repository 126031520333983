import { BASE_URL, Locale } from "@/constants";
import { Vacancy } from "@/interfaces";

export default {
  async getVacancies(locale: Locale): Promise<Vacancy[]> {
    const response = await fetch(`${BASE_URL}vacancies?language=${locale}`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }
    return await response.json();
  },

  async getSingleVacancy(id: string, locale: Locale): Promise<Vacancy[]> {
    const response = await fetch(`${BASE_URL}vacancies/${id}?language=${locale}`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }
    return await response.json();
  },
};
