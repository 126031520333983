import { BASE_URL, Locale } from "@/constants";
import { Region } from "@/interfaces";

export default {
  async getRegions(locale: Locale): Promise<Region[]> {
    const response = await fetch(`${BASE_URL}regions?language=${locale}`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }
    return await response.json();
  },
};
