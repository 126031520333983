import { BASE_URL, Locale } from "@/constants";
import { Person, PersonDetails, PersonQueryParams } from "@/interfaces";

export default {
  async getMediators(queryParams: PersonQueryParams): Promise<Person[]> {
    const params = {
      language: queryParams.locale,
      ...(queryParams.name && { name: queryParams.name }),
      ...(queryParams.region && { region: queryParams.region }),
      ...(queryParams.formOfActivity && { formOfActivity: queryParams.formOfActivity }),
    };
    const response = await fetch(`${BASE_URL}mediators?${new URLSearchParams(params)}`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }
    return await response.json();
  },

  async getMediator(id: number, locale: Locale): Promise<PersonDetails> {
    const response = await fetch(`${BASE_URL}mediators/${id}?language=${locale}`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }
    return await response.json();
  },
};
