import { BASE_URL, Locale } from "@/constants";
import { Person, PersonDetails, PersonQueryParams, Region } from "@/interfaces";

export default {
  async getLaborArbitrators(queryParams: PersonQueryParams): Promise<Person[]> {
    const params = {
      language: queryParams.locale,
      ...(queryParams.name && { name: queryParams.name }),
      ...(queryParams.region && { region: queryParams.region }),
      ...(queryParams.formOfActivity && { formOfActivity: queryParams.formOfActivity }),
    };
    const response = await fetch(`${BASE_URL}arbitrators?${new URLSearchParams(params)}`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }
    return await response.json();
  },

  async getLaborArbitrator(id: number, locale: Locale): Promise<PersonDetails> {
    const response = await fetch(`${BASE_URL}arbitrators/${id}?language=${locale}`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }
    return await response.json();
  },

  async getPrincipals(locale: Locale): Promise<Person[]> {
    const response = await fetch(`${BASE_URL}personas?isPrincipal=true&language=${locale}`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }
    return await response.json();
  },
  async getPerson(id: string, locale: Locale): Promise<PersonDetails> {
    const response = await fetch(`${BASE_URL}personas/${id}?language=${locale}`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }
    return await response.json();
  },
};
