import dayjs from "dayjs";
import { APP_BASE_URL, BASE_URL, Locale, LOCALE_KEY } from "@/constants";
import { News, EventResponse, Department, Galleries, NewsParams, Tag } from "@/interfaces";

export default {
  async getNews(params: NewsParams): Promise<News[]> {
    const {
      language,
      main,
      name,
      limit,
      offset,
      tagsIds,
      departmentID,
      createdBefore,
      createdAfter,
    } = params;
    const queryParams: NewsParams = {
      language: language || this.getLocale(),
      ...(name && { name }),
      ...(main && { main }),
      ...(limit && { limit }),
      ...(offset && { offset }),
      ...(tagsIds && { tagsIds }),
      ...(departmentID && { departmentID }),
      ...(createdBefore && { createdBefore }),
      ...(createdAfter && { createdAfter }),
    };
    const response = await fetch(`${BASE_URL}news?${new URLSearchParams(queryParams as any)}`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }

    const list: News[] = await response.json();

    return list.map((i) => this.mapNews(i));
  },

  async getNewsById(id: string, locale: Locale): Promise<void | News> {
    const url = `${BASE_URL}news/${id}?language=${locale}`;

    return fetch(url, { method: "GET" })
      .then((r) => {
        if (r.ok) return r.json();
        throw new Error("Something went wrong");
      })
      .then((i) => this.mapNews(i))
      .catch(console.error);
  },

  async newsSubscription(Email: string): Promise<void | Response> {
    const response = await fetch(`${BASE_URL}news_subscriptions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ Email }),
    });
    if (!response.ok) {
      throw await response.text();
    }
  },

  async getListOfGalleries(locale: Locale, limit?: number, offset?: number): Promise<Galleries> {
    const queryParams = {
      language: locale || this.getLocale(),
      ...(limit && { limit: limit.toString() }),
      ...(offset && { offset: offset.toString() }),
    };
    const url = `${BASE_URL}galleries/summary/?${new URLSearchParams(queryParams)}`;
    const response = await fetch(url, { method: "GET" });
    const text = await response.text(); // Parse it as text
    if (response.status !== 200) {
      throw response.status;
    }
    const list = text ? JSON.parse(text) : {};
    return list;
  },

  async getListOfEvents(locale: Locale, limit?: number, offset?: number): Promise<EventResponse[]> {
    const queryParams = {
      language: locale || this.getLocale(),
      ...(limit && { limit: limit.toString() }),
      ...(offset && { offset: offset.toString() }),
      StartDate__gte: dayjs(new Date()).format("YYYY-MM-DD"),
    };

    return fetch(`${BASE_URL}events?${new URLSearchParams(queryParams)}`, {
      method: "GET",
    })
      .then((r) => {
        if (r.ok) return r.json();
        throw new Error("Something went wrong");
      })
      .catch((error) => {
        throw error;
      });
  },

  async getTags(locale: Locale): Promise<Tag[]> {
    const queryParams = {
      language: locale || this.getLocale(),
    };

    return fetch(`${BASE_URL}tags?${new URLSearchParams(queryParams)}`, {
      method: "GET",
    })
      .then((r) => {
        if (r.ok) return r.json();
        throw new Error("Something went wrong");
      })
      .catch((error) => {
        throw error;
      });
  },

  async getPopularTags(locale: Locale): Promise<string[]> {
    const queryParams = {
      language: locale || this.getLocale(),
    };

    return fetch(`${BASE_URL}news/tags?${new URLSearchParams(queryParams)}`, {
      method: "GET",
    })
      .then((r) => {
        if (r.ok) return r.json();
        throw new Error("Something went wrong");
      })
      .catch((error) => {
        throw error;
      });
  },

  async getAllEvents(
    locale: Locale,
    department?: string,
    limit?: number,
    offset?: number,
  ): Promise<EventResponse[]> {
    const queryParams: any = {
      language: locale || this.getLocale(),
      ...(department && { department }),
      ...(limit && { limit: limit.toString() }),
      ...(offset && { offset: offset.toString() }),
    };
    const response = await fetch(`${BASE_URL}events/?${new URLSearchParams(queryParams)}`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }
    return response.json();
  },

  async getDepartments(locale: Locale): Promise<Department> {
    const queryParams = {
      language: locale || this.getLocale(),
    };
    const response = await fetch(`${BASE_URL}departments/?${new URLSearchParams(queryParams)}`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }
    return response.json();
  },

  async getDepartment(
    locale: Locale,
    id: string,
    limit?: number,
    offset?: number,
  ): Promise<Department> {
    const queryParams = {
      language: locale || this.getLocale(),
      ...(limit && { limit: limit.toString() }),
      ...(offset && { offset: offset.toString() }),
    };
    const response = await fetch(`${BASE_URL}departments/?${new URLSearchParams(queryParams)}`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }
    const departments = await response.json();
    return departments.find((department: Department) => department.ID === Number(id));
  },

  async getEvent(
    id: string,
    locale: Locale,
    limit?: number,
    offset?: number,
  ): Promise<EventResponse> {
    const queryParams = {
      language: locale || this.getLocale(),
      ...(limit && { limit: limit.toString() }),
      ...(offset && { offset: offset.toString() }),
    };
    const response = await fetch(`${BASE_URL}events/${id}?${new URLSearchParams(queryParams)}`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }
    return response.json();
  },

  storeLocale(locale = Locale.Uk) {
    localStorage.setItem(LOCALE_KEY, locale);
  },

  getLocale(): Locale {
    return (localStorage.getItem(LOCALE_KEY) as Locale) || Locale.Uk;
  },

  mapNews(news: News): News {
    if (news?.Galery?.Cover) {
      news.Galery.Cover = this.setPathForCover(news.Galery.Cover);
    }
    news.Content = news.Content.replaceAll(`src="/media`, `src="${APP_BASE_URL}/media`);

    return news;
  },

  setPathForCover(cover: string) {
    return `${APP_BASE_URL}${cover}`;
  },
};
