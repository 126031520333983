import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomePage from "@/views/HomePage.vue";
import StakeholdersPage from "@/views/StakeholdersPage.vue";
import BulletinPage from "@/views/BulletinPage.vue";
import HistoryPage from "@/views/HistoryPage.vue";
import AllNewsPage from "@/views/AllNewsPage.vue";
import NewsPage from "@/views/NewsPage.vue";
import CardsWrapper from "@/components/CardsWrapper.vue";
import ReportsPage from "@/views/ReportsPage.vue";
import GovernmentClean from "@/views/GovernmentCleans.vue";
import RegulatoryPage from "@/views/RegulatoryPage.vue";
import MediaPublications from "@/views/MediaPublications.vue";
import CentralDepartments from "@/views/CentralDepartments.vue";
import ContactsPage from "@/views/ContactsPage.vue";
import SingleEvent from "@/views/SingleEvent.vue";
import VacanciesAndContests from "@/views/VacanciesAndContests.vue";
import PreventionCorruption from "@/views/PreventionCorruption.vue";
import PublicInformation from "@/views/PublicInformation.vue";
import CollectiveLaborDisputesRegister from "@/views/CollectiveLaborDisputesRegister.vue";
import { TabsConfig } from "@/constants/tabs-config";
import CooperationPage from "@/views/CooperationPage.vue";
import AllEvents from "@/views/AllEvents.vue";
import PartiesEntitiesRegister from "@/views/PartiesEntitiesRegister.vue";
import GalleryPage from "@/views/GalleryPage.vue";
import CollectiveDisputesConflicts from "@/views/areasOfActivities/CollectiveDisputesConflicts.vue";
import CollectiveDisputesAlgorithm from "@/views/areasOfActivities/CollectiveDisputesAlgorithm.vue";
import CollectiveDisputesTrainingLaborArbitrators from "@/views/areasOfActivities/CollectiveDisputesTrainingLaborArbitrators.vue";
import CollectiveDisputesPrevention from "@/views/areasOfActivities/CollectiveDisputesPrevention.vue";
import SocialDialogueConcept from "@/views/areasOfActivities/SocialDialogueConcept.vue";
import SocialDialogueLegislation from "@/views/areasOfActivities/SocialDialogueLegislation.vue";
import SocialDialoguePrinciples from "@/views/areasOfActivities/SocialDialoguePrinciples.vue";
import MediaGallery from "@/views/MediaGallery.vue";
import SocialDialogueDetermination from "@/views/areasOfActivities/SocialDialogueDetermination.vue";
import SocialDialoguePeculiarities from "@/views/areasOfActivities/SocialDialoguePeculiarities.vue";
import SocialDialogueSocioEconomicCouncil from "@/views/areasOfActivities/SocialDialogueSocioEconomicCouncil.vue";
import SocialDialogueForeignExperience from "@/views/areasOfActivities/SocialDialogueForeignExperience.vue";
import SubmitDocuments from "@/views/SubmitDocuments.vue";
import LaborArbitrators from "@/views/LaborArbitrators.vue";
import IndependentMediators from "@/views/IndependentMediators.vue";
import MissionPage from "@/views/MissionPage.vue";
import UsefulLinks from "@/views/UsefulLinks.vue";
import SingleVacancy from "@/views/SingleVacancy.vue";
import GovernmentProcurement from "@/views/GovernmentProcurement.vue";
import AppealPage from "@/views/AppealPageTempSolution.vue";
import PersonPage from "@/views/PersonPage.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "home",
    component: HomePage,
  },
  {
    path: "/resources",
    name: "resources",
    component: CardsWrapper,
    props: {
      title: "menu.resources.title",
      cards: [
        {
          text: "Реєстр колективних трудових спорів (конфліктів)",
          routerLink: "/resources/collective-labor-disputes-register",
        },
        {
          text: "Реєстр репрезентативних суб'єктів сторін профспілок та організацій роботодавців",
          routerLink: "/resources/parties-entities-register",
        },
        {
          text: "Список трудових арбітрів",
          routerLink: "/resources/labor_arbitrators",
        },
        {
          text: "Список незалежних посередників",
          routerLink: "/resources/independent_mediators",
        },
        {
          text: "Бюлетень НСПП",
          routerLink: "/resources/bulletin",
        },
        {
          text: "Корисні посилання",
          routerLink: "/resources/links",
        },
      ],
    },
    meta: {
      headerTitle: "menu.resources.title",
    },
  },
  {
    path: "/resources/bulletin",
    name: "bulletin",
    component: BulletinPage,
    meta: {
      headerTitle: "menu.resources.bulletin",
      breadcrumbs: [{ name: "breadcrumbs.resources", route: "/resources" }],
    },
  },
  {
    path: "/resources/collective-labor-disputes-register",
    name: "collective-labor-disputes-register",
    component: CollectiveLaborDisputesRegister,
    meta: {
      headerTitle: "menu.resources.collectiveLaborDisputesRegister",
      breadcrumbs: [{ name: "breadcrumbs.resources", route: "/resources" }],
    },
  },
  {
    path: "/resources/parties-entities-register",
    name: "parties-entities-register",
    component: PartiesEntitiesRegister,
    meta: {
      headerTitle: "menu.resources.partiesEntitiesRegister",
      breadcrumbs: [{ name: "breadcrumbs.resources", route: "/resources" }],
    },
  },
  {
    path: "/resources/labor_arbitrators",
    name: "labor_arbitrators",
    component: LaborArbitrators,
    meta: {
      headerTitle: "menu.resources.laborArbitrators",
      breadcrumbs: [{ name: "breadcrumbs.resources", route: "/resources" }],
    },
  },
  {
    path: "/resources/independent_mediators",
    name: "independent_mediators",
    component: IndependentMediators,
    meta: {
      headerTitle: "menu.resources.independentMediators",
      breadcrumbs: [{ name: "breadcrumbs.resources", route: "/resources" }],
    },
  },
  {
    path: "/resources/links",
    name: "links",
    component: UsefulLinks,
    meta: {
      headerTitle: "menu.resources.links",
      breadcrumbs: [{ name: "breadcrumbs.resources", route: "/resources" }],
    },
  },
  {
    path: "/about-us",
    name: "about-us",
    component: CardsWrapper,
    props: {
      title: "menu.aboutUs.title",
      cards: [
        {
          text: "menu.aboutUs.mission",
          routerLink: "/about-us/mission",
        },
        {
          text: "menu.aboutUs.history",
          routerLink: "/about-us/history",
        },
        {
          text: "menu.aboutUs.departments",
          routerLink: "/about-us/departments",
        },
        {
          text: "menu.aboutUs.reports",
          routerLink: "/about-us/reports",
        },
        {
          text: "menu.aboutUs.stakeholders",
          routerLink: "/about-us/stakeholders",
        },
        {
          text: "menu.aboutUs.cooperation",
          routerLink: "/about-us/cooperation",
        },
        {
          text: "menu.aboutUs.contacts",
          routerLink: "/about-us/contacts",
        },
      ],
    },
    meta: {
      headerTitle: "menu.aboutUs.title",
    },
  },
  {
    path: "/about-us/cooperation",
    name: "cooperation",
    component: CooperationPage,
    meta: {
      headerTitle: "menu.aboutUs.cooperation",
      breadcrumbs: [{ name: "breadcrumbs.aboutUs", route: "/about-us" }],
    },
  },
  {
    path: "/about-us/mission",
    name: "mission",
    component: MissionPage,
    meta: {
      headerTitle: "menu.aboutUs.mission",
      breadcrumbs: [{ name: "breadcrumbs.aboutUs", route: "/about-us" }],
    },
  },
  {
    path: "/about-us/stakeholders",
    name: "stakeholders",
    component: StakeholdersPage,
    meta: {
      headerTitle: "menu.aboutUs.stakeholders",
      breadcrumbs: [{ name: "breadcrumbs.aboutUs", route: "/about-us" }],
    },
  },
  {
    path: "/about-us/departments",
    name: "departments",
    component: CentralDepartments,
    meta: {
      headerTitle: "menu.aboutUs.departments",
      breadcrumbs: [{ name: "breadcrumbs.aboutUs", route: "/about-us" }],
    },
  },
  {
    path: "/about-us/departments/principal/:id",
    name: "principal",
    component: PersonPage,
    meta: {
      // headerTitle: "Структура",
      breadcrumbs: [{ name: "breadcrumbs.aboutUs", route: "/about-us" }],
    },
  },
  {
    path: "/about-us/contacts",
    name: "contacts",
    component: ContactsPage,
    meta: {
      headerTitle: "menu.aboutUs.contacts",
      breadcrumbs: [{ name: "breadcrumbs.aboutUs", route: "/about-us" }],
    },
  },
  {
    path: "/about-us/history",
    name: "history",
    component: HistoryPage,
    meta: {
      headerTitle: "menu.aboutUs.history",
      breadcrumbs: [{ name: "breadcrumbs.aboutUs", route: "/about-us" }],
    },
  },
  {
    path: "/about-us/reports",
    name: "reports",
    component: ReportsPage,
    meta: {
      headerTitle: "menu.aboutUs.reports",
      breadcrumbs: [{ name: "breadcrumbs.aboutUs", route: "/about-us" }],
    },
  },
  {
    path: "/areas-of-activities",
    name: "areas-of-activities",
    component: CardsWrapper,
    props: {
      title: "menu.areasOfActivities.title",
      cards: [
        {
          text: "menu.areasOfActivities.collectiveDisputes",
          routerLink: "/areas-of-activities/collective-disputes",
        },
        {
          text: "menu.areasOfActivities.socialDialogue",
          routerLink: "/areas-of-activities/social-dialogue",
        },
      ],
    },
    meta: {
      headerTitle: "menu.areasOfActivities.title",
    },
  },
  {
    path: "/areas-of-activities/collective-disputes",
    name: "activity-collective-disputes",
    component: CardsWrapper,
    props: {
      title: "breadcrumbs.activityDisputes",
      cards: [
        {
          text: "menu.areasOfActivities.disputesConflicts",
          routerLink: "/areas-of-activities/collective-disputes/disputes-conflicts",
        },
        {
          text: "menu.areasOfActivities.alorithm",
          routerLink: "/areas-of-activities/collective-disputes/algorithm",
        },
        {
          text: "menu.areasOfActivities.trainingLaborArbitrators",
          routerLink: "/areas-of-activities/collective-disputes/training-labor-arbitrators",
        },
        {
          text: "menu.areasOfActivities.prevention",
          routerLink: "/areas-of-activities/collective-disputes/prevention",
        },
      ],
    },
    meta: {
      headerTitle: "breadcrumbs.activityDisputes",
      breadcrumbs: [{ name: "breadcrumbs.activities", route: "/areas-of-activities" }],
    },
  },
  {
    path: "/areas-of-activities/collective-disputes/disputes-conflicts",
    name: "collective-disputes-conflicts",
    component: CollectiveDisputesConflicts,
    meta: {
      headerTitle: "menu.areasOfActivities.disputesConflicts",
      breadcrumbs: [
        { name: "breadcrumbs.activities", route: "/areas-of-activities" },
        {
          name: "breadcrumbs.activityDisputes",
          route: "/actiareas-of-activitiesvity/collective-disputes",
        },
      ],
    },
  },
  {
    path: "/areas-of-activities/collective-disputes/algorithm",
    name: "algorithm",
    component: CollectiveDisputesAlgorithm,
    meta: {
      headerTitle: "menu.areasOfActivities.alorithm",
      breadcrumbs: [
        { name: "breadcrumbs.activities", route: "/areas-of-activities" },
        { name: "breadcrumbs.activityDisputes", route: "/areas-of-activities/collective-disputes" },
      ],
    },
  },
  {
    path: "/areas-of-activities/collective-disputes/training-labor-arbitrators",
    name: "training-labor-arbitrators",
    component: CollectiveDisputesTrainingLaborArbitrators,
    meta: {
      headerTitle: "menu.areasOfActivities.trainingLaborArbitrators",
      breadcrumbs: [
        { name: "breadcrumbs.activities", route: "/areas-of-activities" },
        { name: "breadcrumbs.activityDisputes", route: "/areas-of-activities/collective-disputes" },
      ],
    },
  },
  {
    path: "/areas-of-activities/collective-disputes/prevention",
    name: "prevention",
    component: CollectiveDisputesPrevention,
    meta: {
      headerTitle: "Запобігання виникненню колективних трудових спорів",
      breadcrumbs: [
        { name: "breadcrumbs.activities", route: "/areas-of-activities" },
        { name: "breadcrumbs.activityDisputes", route: "/areas-of-activities/collective-disputes" },
      ],
    },
  },
  {
    path: "/areas-of-activities/social-dialogue",
    name: "social-dialogue",
    component: CardsWrapper,
    props: {
      title: "menu.areasOfActivities.socialDialogue",
      cards: [
        {
          text: "menu.areasOfActivities.concept",
          routerLink: "/areas-of-activities/social-dialogue/concept",
        },
        {
          text: "menu.areasOfActivities.legislation",
          routerLink: "/areas-of-activities/social-dialogue/legislation",
        },
        {
          text: "menu.areasOfActivities.principles",
          routerLink: "/areas-of-activities/social-dialogue/principles",
        },
        {
          text: "menu.areasOfActivities.determination",
          routerLink: "/areas-of-activities/social-dialogue/determination",
        },
        {
          text: "menu.areasOfActivities.peculiarities",
          routerLink: "/areas-of-activities/social-dialogue/peculiarities-of-submitting-docs",
        },
        {
          text: "menu.areasOfActivities.socioEconomicCouncil",
          routerLink: "/areas-of-activities/social-dialogue/socio-economic-council",
        },
        {
          text: "menu.areasOfActivities.foreignExperience",
          routerLink: "/areas-of-activities/social-dialogue/foreign-experience",
        },
      ],
    },
    meta: {
      headerTitle: "menu.areasOfActivities.socialDialogue",
      breadcrumbs: [{ name: "breadcrumbs.activities", route: "/areas-of-activities" }],
    },
  },
  {
    path: "/areas-of-activities/social-dialogue/concept",
    name: "concept",
    component: SocialDialogueConcept,
    meta: {
      headerTitle: "menu.areasOfActivities.concept",
      breadcrumbs: [
        { name: "breadcrumbs.activities", route: "/areas-of-activities" },
        { name: "breadcrumbs.socialDialogue", route: "/areas-of-activities/social-dialogue" },
      ],
    },
  },
  {
    path: "/areas-of-activities/social-dialogue/legislation",
    name: "legislation",
    component: SocialDialogueLegislation,
    meta: {
      headerTitle: "menu.areasOfActivities.legislation",
      breadcrumbs: [
        { name: "breadcrumbs.activities", route: "/areas-of-activities" },
        { name: "breadcrumbs.socialDialogue", route: "/areas-of-activities/social-dialogue" },
      ],
    },
  },
  {
    path: "/areas-of-activities/social-dialogue/principles",
    name: "principles",
    component: SocialDialoguePrinciples,
    meta: {
      headerTitle: "menu.areasOfActivities.principles",
      breadcrumbs: [
        { name: "breadcrumbs.activities", route: "/areas-of-activities" },
        { name: "breadcrumbs.socialDialogue", route: "/areas-of-activities/social-dialogue" },
      ],
    },
  },
  {
    path: "/areas-of-activities/social-dialogue/determination",
    name: "determination",
    component: SocialDialogueDetermination,
    meta: {
      headerTitle: "menu.areasOfActivities.determination",
      breadcrumbs: [
        { name: "breadcrumbs.activities", route: "/areas-of-activities" },
        { name: "breadcrumbs.socialDialogue", route: "/areas-of-activities/social-dialogue" },
      ],
    },
  },
  {
    path: "/areas-of-activities/social-dialogue/peculiarities-of-submitting-docs",
    name: "peculiarities",
    component: SocialDialoguePeculiarities,
    meta: {
      headerTitle: "menu.areasOfActivities.peculiarities",
      breadcrumbs: [
        { name: "breadcrumbs.activities", route: "/areas-of-activities" },
        { name: "breadcrumbs.socialDialogue", route: "/areas-of-activities/social-dialogue" },
      ],
    },
  },
  {
    path: "/areas-of-activities/social-dialogue/socio-economic-council",
    name: "socioEconomicCouncil",
    component: SocialDialogueSocioEconomicCouncil,
    meta: {
      headerTitle: "menu.areasOfActivities.socioEconomicCouncil",
      breadcrumbs: [
        { name: "breadcrumbs.activities", route: "/areas-of-activities" },
        { name: "breadcrumbs.socialDialogue", route: "/areas-of-activities/social-dialogue" },
      ],
    },
  },
  {
    path: "/areas-of-activities/social-dialogue/foreign-experience",
    name: "foreignExperience",
    component: SocialDialogueForeignExperience,
    meta: {
      headerTitle: "menu.areasOfActivities.foreignExperience",
      breadcrumbs: [
        { name: "breadcrumbs.activities", route: "/areas-of-activities" },
        { name: "breadcrumbs.socialDialogue", route: "/areas-of-activities/social-dialogue" },
      ],
    },
  },
  {
    path: "/press-center",
    name: "press-center",
    component: CardsWrapper,
    props: {
      title: "menu.pressCenter.title",
      cards: [
        {
          text: "Новини",
          routerLink: "/press-center/news",
        },
        {
          text: "Медіатека",
          routerLink: "/press-center/gallery",
        },
        {
          text: "Анонси",
          routerLink: "/press-center/events",
        },
        {
          text: "Публікації у ЗМІ",
          routerLink: "/press-center/pub-in-media",
        },
      ],
    },
    meta: {
      headerTitle: "menu.pressCenter.title",
    },
  },
  {
    path: "/press-center/news",
    name: "all-news",
    component: AllNewsPage,
    meta: {
      headerTitle: "menu.pressCenter.news",
      breadcrumbs: [{ name: "breadcrumbs.pressCenter", route: "/press-center" }],
    },
  },
  {
    path: "/press-center/news/:id",
    name: "news",
    component: NewsPage,
    meta: {
      breadcrumbs: [
        { name: "breadcrumbs.pressCenter", route: "/press-center" },
        { name: "breadcrumbs.news", route: "/press-center/news" },
      ],
    },
  },
  {
    path: "/press-center/events",
    name: "events",
    component: AllEvents,
    meta: {
      headerTitle: "menu.pressCenter.announcements",
      breadcrumbs: [{ name: "breadcrumbs.pressCenter", route: "/press-center" }],
    },
  },
  {
    path: "/press-center/events/:id",
    name: "event",
    component: SingleEvent,
    meta: {
      breadcrumbs: [
        { name: "breadcrumbs.pressCenter", route: "/press-center" },
        { name: "breadcrumbs.events", route: "/press-center/events" },
      ],
    },
  },
  {
    path: "/press-center/gallery",
    name: "media-library",
    component: MediaGallery,
    meta: {
      headerTitle: "menu.pressCenter.mediaLibrary",
      breadcrumbs: [{ name: "breadcrumbs.pressCenter", route: "/press-center" }],
    },
  },
  {
    path: "/press-center/gallery/:id",
    name: "gallery",
    component: GalleryPage,
    meta: {
      breadcrumbs: [
        { name: "breadcrumbs.pressCenter", route: "/press-center" },
        { name: "breadcrumbs.gallery", route: "/press-center/gallery" },
      ],
    },
  },
  {
    path: "/press-center/pub-in-media",
    name: "pub-in-media",
    component: MediaPublications,
    meta: {
      headerTitle: "menu.pressCenter.pubInMedia",
      breadcrumbs: [{ name: "breadcrumbs.pressCenter", route: "/press-center" }],
    },
  },
  {
    path: "/for-public",
    name: "for-public",
    component: CardsWrapper,
    props: {
      title: "breadcrumbs.forPublic",
      cards: [
        {
          text: "Подати звернення",
          routerLink: "/for-public/appeal",
        },
        {
          text: "Доступ до публічної інформації",
          routerLink: "/for-public/public-information",
        },
        {
          text: "Очищення влади",
          routerLink: "/for-public/government-cleans",
        },
        {
          text: "Державні закупівлі",
          routerLink: "/for-public/government-procurement",
        },
        {
          text: "Запобігання та протидія корупції",
          routerLink: "/for-public/prevention-corruption",
        },
        {
          text: "Вакансії та конкурси",
          routerLink: "/for-public/vacancies/actual",
        },
      ],
    },
    meta: {
      headerTitle: "breadcrumbs.forPublic",
    },
  },
  {
    path: "/for-public/appeal",
    name: "appeal",
    component: AppealPage,
    meta: {
      headerTitle: "Подати звернення",
      breadcrumbs: [{ name: "breadcrumbs.forPublic", route: "/for-public" }],
    },
  },
  {
    path: "/for-public/government-cleans",
    name: "government-cleans",
    component: GovernmentClean,
    meta: {
      headerTitle: "Очищення влади",
      breadcrumbs: [{ name: "breadcrumbs.forPublic", route: "/for-public" }],
    },
  },
  // {
  //   path: "/for-public/submit-documents",
  //   name: "submit-documents",
  //   component: SubmitDocuments,
  //   meta: {
  //     headerTitle: "menu.forPublic.submitDocuments",
  //     breadcrumbs: [{ name: "breadcrumbs.forPublic", route: "/for-public" }],
  //   },
  // },
  {
    path: "/for-public/vacancies/actual",
    name: "vacancies-actual",
    component: VacanciesAndContests,
    meta: {
      headerTitle: "Вакансії та конкурси",
      breadcrumbs: [{ name: "breadcrumbs.forPublic", route: "/for-public" }],
      tabsConfig: TabsConfig.VACANCIES,
      tab: 1,
    },
  },
  {
    path: "/for-public/vacancies/actual/:id",
    name: "vacancies-one",
    component: SingleVacancy,
    meta: {
      headerTitle: "Вакансії та конкурси",
      breadcrumbs: [
        { name: "breadcrumbs.forPublic", route: "/for-public" },
        { name: "breadcrumbs.vacancies", route: "/for-public/vacancies/actual" },
      ],
    },
  },
  {
    path: "/for-public/vacancies/results",
    name: "vacancies-results",
    component: VacanciesAndContests,
    meta: {
      headerTitle: "Вакансії та конкурси",
      breadcrumbs: [{ name: "breadcrumbs.forPublic", route: "/for-public" }],
      tabsConfig: TabsConfig.VACANCIES,
      tab: 2,
    },
  },
  {
    path: "/for-public/vacancies/info",
    name: "vacancies-info",
    component: VacanciesAndContests,
    meta: {
      headerTitle: "Вакансії та конкурси",
      breadcrumbs: [{ name: "breadcrumbs.forPublic", route: "/for-public" }],
      tabsConfig: TabsConfig.VACANCIES,
      tab: 3,
    },
  },
  {
    path: "/for-public/public-information",
    name: "public-information",
    component: PublicInformation,
    meta: {
      headerTitle: "Доступ до публічної інформації",
      breadcrumbs: [{ name: "breadcrumbs.forPublic", route: "/for-public" }],
    },
  },
  {
    path: "/for-public/prevention-corruption",
    name: "prevention-corruption",
    component: PreventionCorruption,
    meta: {
      headerTitle: "Запобігання та протидія корупції",
      breadcrumbs: [{ name: "breadcrumbs.forPublic", route: "/for-public" }],
    },
  },
  {
    path: "/for-public/government-procurement",
    name: "government-procurement",
    component: GovernmentProcurement,
    meta: {
      headerTitle: "Державні закупівлі",
      breadcrumbs: [{ name: "breadcrumbs.forPublic", route: "/for-public" }],
    },
  },
  {
    path: "/regulatory/legislation",
    name: "regulatory-legislation",
    component: RegulatoryPage,
    meta: {
      tabsConfig: TabsConfig.REGULATORY,
      headerTitle: "Нормативна база",
      tab: 1,
    },
  },
  {
    path: "/regulatory/activity",
    name: "regulatory-activity",
    component: RegulatoryPage,
    meta: {
      tabsConfig: TabsConfig.REGULATORY,
      headerTitle: "Нормативна база",
      tab: 2,
    },
  },
  {
    path: "/regulatory/judicial-practice",
    name: "regulatory-judicial-practice",
    component: RegulatoryPage,
    meta: {
      tabsConfig: TabsConfig.REGULATORY,
      headerTitle: "Нормативна база",
      tab: 3,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
