import { BASE_URL, Locale, LOCALE_KEY } from "@/constants";
import { Gallery } from "@/interfaces/galleries.interface";

export default {
  async getMedia(id: string, locale: Locale, limit?: number, offset?: number): Promise<Gallery> {
    const queryParams = {
      language: locale || this.getLocale(),
      ...(limit && { limit: limit.toString() }),
      ...(offset && { offset: offset.toString() }),
    };
    const response = await fetch(`${BASE_URL}galleries/${id}?${new URLSearchParams(queryParams)}`, {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }
    return response.json();
  },

  async getAllMedia(
    locale: Locale,
    name?: string,
    department?: string,
    contentType?: string,
    limit?: number,
    offset?: number,
  ): Promise<Gallery[]> {
    const queryParams = {
      language: locale || this.getLocale(),
      ...(limit && { limit: limit.toString() }),
      ...(offset && { offset: offset.toString() }),
      ...(name && { name: name.toString() }),
      ...(department && { department: department.toString() }),
      ...(contentType && { contentType: contentType.toString() }),
    };
    const response = await fetch(
      `${BASE_URL}galleries/library?${new URLSearchParams(queryParams)}`,
      {
        method: "GET",
      },
    );
    if (response.status !== 200) {
      throw response.status;
    }
    return response.json();
  },

  getLocale(): Locale {
    return (localStorage.getItem(LOCALE_KEY) as Locale) || Locale.Uk;
  },
};
