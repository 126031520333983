<script setup lang="ts"></script>

<template>
  <div class="creative-commons-logo d-flex">
    <img
      class="creative-commons-logo-image"
      src="/img/logo/creative_licence.svg"
      alt="Creative Commons logo"
    />
    <span class="creative-commons-logo-text">
      {{ $t("home.license") }}
    </span>
  </div>
</template>
