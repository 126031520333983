import { BASE_URL } from "@/constants";
import { MediaPublication } from "@/interfaces";

export default {
  async getPublications(): Promise<MediaPublication[]> {
    const response = await fetch(BASE_URL + "media_publications", {
      method: "GET",
    });
    if (response.status !== 200) {
      throw response.status;
    }
    return response.json();
  },
};
